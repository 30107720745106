body {
  background-color: #4e4d45;
  color: #e0fcb4;
}

.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 64px;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
}

.header {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.bio {
  text-align: center;
  color: #cec489;
  margin-top: 16px;
}

.cta {
  text-align: center;
  color: #cec489;
  margin-top: 16px;
  max-width: 450px;
}

.reachButton {
  cursor: pointer;
  margin-top: 16px;
  padding: 8px;
  border: 0;
  border-radius: 5px;
}

.outReachCard {
  margin-top: 16px;
  padding: 8px;
  background-color: #696444;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #696444;
  color: rgb(58, 54, 20);
  text-align: center;
}